import {useState, useEffect, useRef, useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import DateRangeCalendar from 'src/components/elements/dateRangeCalendar/DateRangeCalendar'
import {useOutsideClick} from 'src/utils/dom'
import {DatesStatusMap} from 'src/types/form'
import {SelectDateProps} from 'src/types/utils'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import AppTypography from 'src/components/elements/typography/AppTypography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Visible from 'src/components/helpers/Visible'
import {getDateDifferences, getFormattedDate} from 'src/utils/date'
import {useCurrentLocale} from 'src/hooks/locale'

export default function DateRangePicker(props: {
  opened?: boolean
  showPrice?: boolean
  selectedDates: SelectDateProps
  datesStatusMap?: DatesStatusMap
  disableFlexibleDates?: boolean
  disableSmallSize?: boolean
  disableButtons?: boolean
  vertical?: boolean
  flexibleButtonStyles?: React.CSSProperties
  changeOnSelect?: boolean
  visibleMonths: string[]
  setSelectedDates: (value: SelectDateProps) => void
  halfDayPicker?: boolean
  onClose?: () => void
  onFlexibleDates?: () => void
  nextMonth: () => void
  previousMonth: () => void
  scrollToTop?: () => void
}) {
  const {
    datesStatusMap,
    showPrice,
    opened,
    selectedDates,
    setSelectedDates,
    onClose,
    disableSmallSize,
    vertical,
    disableButtons,
    changeOnSelect,
    visibleMonths,
    previousMonth,
    nextMonth,
    scrollToTop,
    halfDayPicker,
  } = props

  const ref = useRef(null)
  const {t} = useTranslation('common')
  const currentLocale = useCurrentLocale()
  const [values, setValues] = useState<SelectDateProps>(selectedDates)

  //this dosent change anything....
  // const [isFlexible, setIsFlexible] = useState(false)

  const onChange = (values: SelectDateProps) => {
    //setIsFlexible(false)

    if (changeOnSelect && values[1] !== null) {
      setSelectedDates(values)
      setValues(values)
      return
    }

    setValues(values)
  }

  const onApply = () => {
    setSelectedDates(values)

    if (onClose) {
      onClose()
    }
  }

  const onCancel = () => {
    //setIsFlexible(false)
    setValues([null, null])
    setSelectedDates([null, null])

    if (onClose) {
      onClose()
    }
  }

  /*const onClear = () => {
    //setIsFlexible(true)
    setValues([null, null])

    if (onFlexibleDates) {
      setSelectedDates([null, null])
      onFlexibleDates()
    }
  } */

  useOutsideClick(ref, () => {
    if (opened) {
      onApply()
    }
  })

  useEffect(() => {
    if (changeOnSelect) {
      setValues(selectedDates)
    } else if (values[0] === null) {
      //only change local values if we didnt select a first dates
      setValues(selectedDates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates])

  const valuesStr = useMemo(() => {
    const startStr = values[0]
      ? getFormattedDate(values[0], currentLocale)
      : t('check_in')
    const endStr = values[1]
      ? getFormattedDate(values[1], currentLocale)
      : t('check_out')

    const nights = getDateDifferences(values[0], values[1])

    return {
      start: startStr,
      end: endStr,
      nights,
    }
  }, [t, currentLocale, values])

  return (
    <div ref={ref} id="dates_selector">
      <div className="flex flex-col gap-2">
        <DateRangeCalendar
          nextMonth={nextMonth}
          previousMonth={previousMonth}
          visibleMonths={visibleMonths}
          selectedDates={values}
          setSelectedDates={onChange}
          selectMode
          disableSmallSize={disableSmallSize}
          vertical={vertical}
          scrollToTop={scrollToTop}
          halfDayPicker={halfDayPicker}
          showPrice={showPrice}
          datesStatusMap={datesStatusMap}
        />
        {/*
         <FlexibleDates
          disable={disableFlexibleDates}
          isFlexible={isFlexible}
          onClick={onClear}
          flexibleButtonStyles={flexibleButtonStyles}
        />
         */}

        <div className="border-t-0 md:border-t md:border-divider w-full md:flex flex-row justify-center md:justify-between items-center gap-4 p-4">
          <div className="w-full md:w-1/2 space-y-1">
            <div className=" flex flex-row justify-center md:justify-start items-center gap-3">
              <AppTypography variant="subHeading" className="">
                {valuesStr.start}
              </AppTypography>
              <ArrowForwardIcon style={{color: 'black', fontSize: 12}} />
              <AppTypography variant="subHeading" className="">
                {valuesStr.end}
              </AppTypography>
            </div>
            <Visible when={valuesStr.nights > 0}>
              <AppTypography
                variant="subHeading"
                className="text-center md:text-left"
              >
                {valuesStr.nights} {t('nights')}
              </AppTypography>
            </Visible>
          </div>
          <Visible when={!disableButtons}>
            <div className="w-full md:w-1/2 flex flex-row justify-end items-start gap-4">
              <AppRoundedButton variant="outlined" onClick={onCancel}>
                {t('clear')}
              </AppRoundedButton>
              <AppRoundedButton
                color="primary"
                variant="contained"
                onClick={onApply}
              >
                {t('apply')}
              </AppRoundedButton>
            </div>
          </Visible>
        </div>
      </div>
    </div>
  )
}

/*
function FlexibleDates(props: {
  disable?: boolean
  isFlexible: boolean
  flexibleButtonStyles?: React.CSSProperties
  onClick: () => void
}) {
  const {disable, isFlexible, onClick, flexibleButtonStyles} = props

  const [status, setStatus] = useState(false)

  const {t} = useTranslation('common')

  const handleClick = () => {
    setStatus(!status)
    onClick()
  }

  useEffect(() => {
    setStatus(isFlexible)
  }, [isFlexible])

  if (disable) {
    return null
  }

  return (
    <AppButton
      children={t('flexible_dates')}
      variant={status ? 'contained' : 'outlined'}
      color={status ? 'primary' : 'default'}
      onClick={handleClick}
      style={
        flexibleButtonStyles || {
          height: 40,
          borderRadius: 24,
          marginLeft: 8,
          marginRight: 8,
        }
      }
    />
  )
}
*/
