import {memo} from 'react'
import {dayjs} from 'src/utils/date'
import {makeStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeftIcon from 'src/components/elements/icons/ArrowLeft'
import ArrowRightIcon from 'src/components/elements/icons/ArrowRight'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {ucFirst} from 'src/utils/other'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette}) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: spacing(1.5, 1.5),
  },
  icon: {
    fontSize: '19px !important',
    // color: 'white',
  },
  iconBtn: {
    alignItems: 'center',
    display: 'flex',
    height: spacing(5.25),
    width: spacing(5.25),
    justifyContent: 'center',
    padding: 0,
    border: `1px solid ${palette.grey['300']}`,
    '&:hover': {
      border: `1px solid ${palette.grey['300']}`,
    },
  },
  fakeIconBtn: {
    height: spacing(5.25),
    width: spacing(5.25),
  },
}))

function DateRangeCalendarNavbar(props: {
  small?: boolean
  left?: boolean
  right?: boolean
  monthDate: string
  onNext: () => void
  onPrevious: () => void
}) {
  const {left, right, small, monthDate, onNext, onPrevious} = props

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Content
        left={left}
        right={right}
        small={small}
        monthDate={monthDate}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  )
}

function Content(props: {
  small?: boolean
  left?: boolean
  right?: boolean
  monthDate: string
  onNext: () => void
  onPrevious: () => void
}) {
  const classes = useStyles()

  const {left, right, small, monthDate, onNext, onPrevious} = props

  const localOnNext = () => {
    //make sure animation work better
    setTimeout(() => {
      onNext()
    }, 1)
  }
  const localOnPrevious = () => {
    //make sure animation work better
    setTimeout(() => {
      onPrevious()
    }, 1)
  }

  if (left) {
    return (
      <>
        <IconButton
          className={classes.iconBtn}
          onClick={localOnPrevious}
          aria-label="Left"
        >
          <ArrowLeftIcon className={classes.icon} />
        </IconButton>
        <YearMonth monthDate={monthDate} />
        <div className={classes.fakeIconBtn} />
      </>
    )
  }

  if (right) {
    return (
      <>
        <div className={classes.fakeIconBtn} />
        <YearMonth monthDate={monthDate} />
        <IconButton
          className={classes.iconBtn}
          onClick={localOnNext}
          aria-label="Right"
        >
          <ArrowRightIcon className={classes.icon} />
        </IconButton>
      </>
    )
  }

  if (small) {
    return (
      <>
        <IconButton
          className={classes.iconBtn}
          onClick={localOnPrevious}
          aria-label="Left"
        >
          <ArrowLeftIcon className={classes.icon} />
        </IconButton>
        <YearMonth monthDate={monthDate} />
        <IconButton
          className={classes.iconBtn}
          onClick={localOnNext}
          aria-label="Right"
        >
          <ArrowRightIcon className={classes.icon} />
        </IconButton>
      </>
    )
  }

  return null
}

function YearMonth(props: {monthDate: string}) {
  const {monthDate} = props

  if (!monthDate) {
    return null
  }

  return (
    <AppTypography variant="displaySmall" neutralColor={800}>
      {ucFirst(dayjs(monthDate, 'YYYY-MM').format('MMMM YYYY'))}
    </AppTypography>
  )
}

export default memo(DateRangeCalendarNavbar)
