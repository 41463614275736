export const ICALS = [
  {
    name: 'Booking',
    color: '#273B7D',
  },
  {
    name: 'Airbnb',
    color: '#FF5A5F',
  },
  {
    name: 'Vrbo',
    color: '#2B5ABC',
  },
  {
    name: 'Airbnb',
    color: '#FF5A5F',
  },
  {
    name: 'GlampingHub',
    color: '#23918D',
  },
  {
    name: 'Other',
    color: '#19647E',
  },
]
