import {FormOption} from 'src/types/form'
import {getValueFromLabel} from 'src/utils/other'
import {CURRENCY_SYMBOL_CAD, CURRENCY_TYPE_CAD} from './currency'

const rangeOptions = (customRange?: number) => {
  return Array.from({length: customRange || 60})?.map((_, index) => ({
    value: index + 1,
    label: `${index + 1}`,
  }))
}

//todo change that to handle directly
export const CUSTOMER_EXPECTATIONS = [
  'Nearby noise potential',
  'Cell phone reception is poor near this property',
  'Access with a four-wheel drive vehicle is preferable in winter',
  'Water is not drinkable',
  'Must climb stairs',
]

export const CUSTOMER_EXPECTATION_FORM_VALUES = CUSTOMER_EXPECTATIONS.map(
  (label, index) => ({
    label,
    formValue: getValueFromLabel(label),
    index,
  }),
)

export const getCustomerExpectationLabelFromValue = (item: string) => {
  const find = CUSTOMER_EXPECTATION_FORM_VALUES.find(
    (value) => item === value.formValue,
  )

  if (find) {
    return find.label
  }

  return null
}

export const RULES = [
  'Minimum age to rent',
  'Suitable for children (2-12 years)',
  'Suitable for babies (under 2 years old)',
  'Pets allowed',
  'Smoking allowed inside',
  'Possible events or parties',
  'Events allowed',
  'Open fires allowed',
]

export const RULES_SELECT_MENU = [
  {
    label: 'Minimum age to rent',
    options: rangeOptions(),
  },
]

export const RULES_FORM_VALUES = RULES.map((label, index) => ({
  label,
  formValue: index === 0 ? 0 : getValueFromLabel(label),
  options: index === 0 ? rangeOptions() : null,
  index,
}))

export const getRuleLabelFromValue = (item: string) => {
  const find = RULES_FORM_VALUES.find((value) => {
    if (value.label === 'Minimum age to rent' && typeof item == 'number') {
      return true
    }
    return item === value.formValue
  })

  if (find) {
    if (find.label === 'Minimum age to rent' && typeof item == 'number') {
      return find.label + ' ' + item
    }
    return find.label
  }

  return null
}

export const CANCELLATION_POLICIES = [
  {
    label: 'Soft',
    value: 'soft',
    content: {
      en: [
        `Cancellations made up to 10 days before the start of the reservation can be canceled free of charge.`,
        `Cancellations made less than 10 days before the arrival date at the cottage will result in the loss of 30% of the total reservation amount as damages.`,
        `Cancellations made less than 2 days before the arrival date at the cottage will result in the loss of all amounts received so far (100% of the total reservation amount). Only the security deposit for breakage and damage will be refunded to tenants.`,
      ],
      fr: [
        `Une annulation effectuée jusqu’à 10 jours avant le début de la réservation peut être annulée sans frais.`,
        `Une annulation effectuée moins de 10 jours avant la date d'arrivée au chalet entraînera la perte d'un montant de 30% du montant total de la réservation à titre de dommages et intérêts.`,
        `Une annulation effectuée moins de 2 jours avant la date d'arrivée au chalet entraînera la perte de toutes les sommes perçues jusqu'à présent (100% du montant total de la réservation).  Seul le dépôt de sécurité pour bris et dommage sera remboursé aux locataires.`,
      ],
    },
  },
  {
    label: 'Moderate',
    value: 'moderate',
    content: {
      en: [
        `Cancellations made up to 30 days before the start of the reservation can be canceled free of charge.`,
        `Cancellations made less than 30 days and more than 10 days before the arrival date at the cottage will result in the loss of 30% of the total reservation amount as damages.`,
        `Cancellations made less than 10 days before the arrival date at the cottage will result in the loss of all amounts received so far (100% of the total reservation amount). Only the security deposit for breakage and damage will be refunded to tenants.`,
      ],
      fr: [
        `Une annulation effectuée jusqu’à 30 jours avant le début de la réservation peut être annulée sans frais.`,
        `Une annulation effectuée moins de 30 jours et plus de 10 jours avant la date d'arrivée au chalet entraînera la perte d'un montant de 30% du montant total de la réservation à titre de dommages et intérêts.`,
        `Une annulation effectuée moins de 10 jours avant la date d'arrivée au chalet entraînera la perte de toutes les sommes perçues jusqu'à présent (100% du montant total de la réservation).  Seul le dépôt de sécurité pour bris et dommage sera remboursé aux locataires.`,
      ],
    },
  },
  {
    label: 'Strict',
    value: 'strict',
    content: {
      en: [
        `Cancellations made within 24 hours of booking confirmation can be canceled free of charge.`,
        `Cancellations made more than 60 days before the arrival date at the cottage will result in the loss of 10% of the total reservation amount as damages.`,
        `Cancellations made more than 30 days before the arrival date at the cottage will result in the loss of the booking deposit (30% of the total reservation amount) as damages.`,
        `Cancellations made less than 30 days before the arrival date at the cottage will result in the loss of all amounts received so far (100% of the total reservation amount). Only the security deposit for breakage and damage will be refunded to tenants.`,
      ],
      fr: [
        `Une annulation effectuée dans les 24h suivant la confirmation de la réservation peut être annulée sans frais.`,
        `Une annulation effectuée au-delà de 60 jours avant la date d'arrivée au chalet entraînera la perte d'un montant de 10% du montant total de la réservation à titre de dommages et intérêts.`,
        `Une annulation effectuée au-delà de 30 jours avant la date d'arrivée au chalet entraînera la perte du dépôt de réservation (30% du montant total de la réservation) à titre de dommages et intérêts.`,
        `Une annulation effectuée moins de 30 jours avant la date d'arrivée au chalet entraînera la perte de toutes les sommes perçues jusqu'à présent (100% du montant total de la réservation).  Seul le dépôt de sécurité pour bris et dommage sera remboursé aux locataires.`,
      ],
    },
  },
  {
    label: 'Rigid',
    value: 'rigid',
    content: {
      en: [
        `Cancellations made within 24 hours of booking confirmation can be canceled free of charge.`,
        `Cancellations made more than 90 days before the arrival date at the cottage will result in the loss of 10% of the total reservation amount as damages.`,
        `Cancellations made more than 60 days before the arrival date at the cottage will result in the loss of the booking deposit (30% of the total reservation amount) as damages.`,
        `Cancellations made less than 60 days before the arrival date at the cottage will result in the loss of all amounts received so far (100% of the total reservation amount). Only the security deposit for breakage and damage will be refunded to tenants.`,
      ],
      fr: [
        `Une annulation effectuée dans les 24h suivant la confirmation de la réservation peut être annulée sans frais.`,
        `Une annulation effectuée au-delà de 90 jours avant la date d'arrivée au chalet entraînera la perte d'un montant de 10% du montant total de la réservation à titre de dommages et intérêts.`,
        `Une annulation effectuée au-delà de 60 jours avant la date d'arrivée au chalet entraînera la perte du dépôt de réservation (30% du montant total de la réservation) à titre de dommages et intérêts.`,
        `Une annulation effectuée moins de 60 jours avant la date d'arrivée au chalet entraînera la perte de toutes les sommes perçues jusqu'à présent (100% du montant total de la réservation).  Seul le dépôt de sécurité pour bris et dommage sera remboursé aux locataires.`,
      ],
    },
  },
]

export const SECURITIES = [
  'Smoke detector',
  'Carbon monoxide detector',
  'First aid kit',
  'Fire extinguisher',
  'Chamber door lock',
].map((label, index) => ({
  label,
  formValue: getValueFromLabel(label),
  index,
}))

export const getSecurityLabelFromValue = (item: string) => {
  const find = SECURITIES.find((value) => item === value.formValue)

  if (find) {
    return find.label
  }

  return null
}

export const internetTypeOptions = [
  {
    label: 'Unstable',
    value: 'Unstable',
    content: {
      en: 'Speed: 0 to 5 Mbps',
      fr: 'Vitesse: 0 à 5 Mbit/s',
    },
  },
  {
    label: 'Basic',
    value: 'Basic',
    content: {
      en: 'Speed: 5 to 10 Mbps',
      fr: 'Vitesse: 5 à 10 Mbit/s',
    },
  },
  {
    label: 'Fast',
    value: 'Fast',
    content: {
      en: 'Speed: 10 to 30 Mbps',
      fr: 'Vitesse: 10 à 30 Mbit/s',
    },
  },
  {
    label: 'Very fast',
    value: 'Very fast',
    content: {
      en: 'Speed: 30 Mbps and above',
      fr: 'Vitesse: 30 Mbit/s et plus',
    },
  },
  {
    label: 'No internet connection',
    value: 'No internet connection',
    content: {
      en: '',
      fr: '',
    },
  },
  {
    label: '-',
    value: '0',
    content: {
      en: '',
      fr: '',
    },
  },
]

export const checkInOptions = [
  {
    label: 'Smart Lock',
    value: 'Smart Lock',
  },
  {
    label: 'Lock with keypad',
    value: 'Lock with keypad',
  },
  {
    label: 'Lock box',
    value: 'Lock box',
  },
  {
    label: 'In person',
    value: 'In person',
  },
  {
    label: '-',
    value: '0',
  },
]

export const rateFields = [
  {
    label: 'base_rate',
    name: 'base_rate',
    placeholder: `${CURRENCY_TYPE_CAD} (${CURRENCY_SYMBOL_CAD})`,
    description: 'base_rate_description',
  },
  {
    label: 'weekend_rate',
    name: 'weekend_rate',
    placeholder: `${CURRENCY_TYPE_CAD} (${CURRENCY_SYMBOL_CAD})`,
    description: 'weekend_rate_description',
  },
  {
    label: 'weekly_rate',
    name: 'weekly_rate',
    placeholder: `${CURRENCY_TYPE_CAD} (${CURRENCY_SYMBOL_CAD})`,
    description: 'weekly_rate_description',
  },
  {
    label: 'monthly_rate',
    name: 'monthly_rate',
    placeholder: `${CURRENCY_TYPE_CAD} (${CURRENCY_SYMBOL_CAD})`,
    description: 'monthly_rate_description',
  },
]

export const currencies = [
  {
    label: `${CURRENCY_TYPE_CAD} (${CURRENCY_SYMBOL_CAD})`,
    value: CURRENCY_TYPE_CAD,
  },
  // {
  //   label: 'USD ($)',
  //   value: 'USD',
  // },
]

export const optionalPropertyInformation = [
  {
    label: 'your_accomodation',
    // name: 'accomodation',
    optional: true,
    placeholder: 'you_can_type_here',
    description: {
      highlightedText: 'advice',
      normalText: 'your_accomodation_advice',
    },
  },
  {
    label: 'your_availabilities',
    // name: 'availability',
    optional: true,
    placeholder: 'you_can_type_here',
    description: {
      highlightedText: 'advice',
      normalText: 'your_availabilities_advice',
    },
  },
  {
    label: 'your_neighbourhood',
    // name: 'neighbourhood',
    optional: true,
    placeholder: 'you_can_type_here',
    description: {
      highlightedText: 'advice',
      normalText: 'your_neighbourhood_advice',
    },
  },
  {
    label: 'move',
    // name: 'move',
    optional: true,
    placeholder: 'you_can_type_here',
    description: {
      highlightedText: 'advice',
      normalText: 'move_advice',
    },
  },
  {
    label: 'other_information',
    // name: 'other',
    optional: true,
    placeholder: 'you_can_type_here',
    description: {
      highlightedText: '',
      normalText: 'other_information_advice',
    },
  },
]

export const accessOptions: FormOption[] = [
  {
    info: 'entire_accommodation_info',
    label: 'entire_accommodation',
    value: 'entire',
  },
  {
    info: 'private_room_info',
    label: 'private_room',
    value: 'private',
  },
  {
    info: 'shared_room_info',
    label: 'shared_room',
    value: 'shared',
  },
]

export const propertyOptions: FormOption[] = [
  {
    label: 'personal_property',
    value: 'personal',
  },
  {
    label: 'property_dedicated_to_rental',
    value: 'rental',
  },
]
/*
    xs: 0,
    sm: 552,
    md: 900,
    lg: 1128,
    xl: 1360,
 */

//todo create api for that
export const getSearchColumns = (t: any) => [
  {
    title: '📍 ' + t('nearby_to'),
    locations: [
      {
        label: 'Montréal',
        options: {
          xs: [7, 45.5018869, -73.5673919], //zoom, lat, lng
          sm: [8, 45.305771, -73.2544903],
          md: [8, 45.5018869, -73.5673919],
          lg: [8, 45.5018869, -73.5673919],
          xl: [9, 45.5018869, -73.5673919],
        },
      },
      {
        label: 'Québec',
        options: {
          xs: [9, 46.8130816, -71.2074596],
          sm: [9, 46.8051985, -71.4163655],
          md: [9, 46.8130816, -71.2074596],
          lg: [9, 46.8130816, -71.2074596],
          xl: [10, 46.8130816, -71.2074596],
        },
      },
      {
        label: 'Trois-Rivières',
        options: {
          xs: [9, 46.6513137, -72.8470952],
          sm: [9, 46.5825171, -72.7723438],
          md: [9, 46.5618559, -72.7435254],
          lg: [9, 46.5618559, -72.7435254],
          xl: [10, 46.5618559, -72.7435254],
        },
      },
      {
        label: 'Sherbrooke',
        options: {
          xs: [9, 45.4042669, -71.893674],
          sm: [9, 45.2238625, -72.3952237],
          md: [9, 45.3440765, -72.5164034],
          lg: [9, 45.3440765, -72.5164034],
          xl: [10, 45.3440765, -72.5164034],
        },
      },
      {
        label: 'Saguenay',
        options: {
          xs: [8, 48.620865, -71.111136],
          sm: [8, 48.6401453, -71.5429092],
          md: [9, 48.5549971, -71.32456],
          lg: [9, 49.033333, -71.616667],
          xl: [9, 49.033333, -71.616667],
        },
      },
      {
        label: 'Gatineau',
        options: {
          xs: [8, 45.802541, -75.4788469],
          sm: [8, 45.8339003, -75.6843518],
          md: [8, 45.4215296, -75.6971931],
          lg: [9, 45.5971928, -75.2432687],
          xl: [9, 45.5179364, -75.7410977],
        },
      },
    ],
  },
  {
    title: '😍 ' + t('popular_destinations'),
    locations: [
      {
        label: 'Le Massif de Charlevoix',
        options: {
          xs: [9, 47.5955566, -70.5890566],
          sm: [10, 47.307743, -70.568109],
          md: [10, 47.307743, -70.568109],
          lg: [10, 47.307743, -70.568109],
          xl: [11, 47.307743, -70.568109],
        },
      },
      {
        label: 'Mont-Tremblant',
        options: {
          xs: [9, 46.1184616, -74.5961852],
          sm: [8, 45.9720294, -74.6078762],
          md: [8, 46.1184616, -74.5961852],
          lg: [8, 46.1184616, -74.5961852],
          xl: [9, 46.1184616, -74.5961852],
        },
      },
      {
        label: 'Mont-Saint-Anne',
        options: {
          xs: [10, 47.1314168, -70.8642474],
          sm: [10, 47.1090821, -70.8771849],
          md: [11, 47.116667, -70.85],
          lg: [10, 47.116667, -70.85],
          xl: [12, 47.116667, -70.85],
        },
      },
      {
        label: 'Stoneham',
        options: {
          xs: [10, 47.12095, -71.409135],
          sm: [10, 47.12095, -71.409135],
          md: [10, 47.12095, -71.409135],
          lg: [10, 47.12095, -71.409135],
          xl: [11, 47.12095, -71.409135],
        },
      },
      {
        label: 'Le Massif du Sud',
        options: {
          xs: [9, 46.3241312, -70.3977787],
          sm: [8, 45.5801342, -70.8881],
          md: [10, 46.1229184, -70.670352],
          lg: [10, 46.1229184, -70.670352],
          xl: [10, 46.4935073, -70.2136365],
        },
      },
      {
        label: 'Mont Grand-Fonds',
        options: {
          xs: [11, 47.6569684, -70.152187],
          sm: [11, 47.6569684, -70.152187],
          md: [10, 47.6569684, -70.152187],
          lg: [10, 47.6569684, -70.152187],
          xl: [12, 47.6569684, -70.152187],
        },
      },
      {
        label: 'Val Saint-Côme',
        options: {
          xs: [10, 46.270537, -73.771845],
          sm: [10, 46.270537, -73.771845],
          md: [10, 46.270537, -73.771845],
          lg: [10, 46.270537, -73.771845],
          xl: [10, 46.270537, -73.771845],
        },
      },
      {
        label: 'Saint-Sauveur',
        options: {
          xs: [10, 45.8957004, -74.1564982],
          sm: [10, 45.8957004, -74.1564982],
          md: [10, 45.8957004, -74.1564982],
          lg: [10, 45.8957004, -74.1564982],
          xl: [11, 45.8957004, -74.1564982],
        },
      },
    ],
  },
  {
    title: '🔥 ' + t('popular_regions'),
    locations: [
      {
        label: 'Charlevoix',
        options: {
          xs: [9, 47.442398, -70.5037557],
          sm: [10, 47.3997263, -70.3605089],
          md: [10, 47.442398, -70.5037557],
          lg: [10, 47.442398, -70.5037557],
          xl: [11, 47.442398, -70.5037557],
        },
      },
      {
        label: 'Laurentides',
        options: {
          xs: [9, 46.1006948, -74.3946752],
          sm: [8, 45.9720294, -74.6078762],
          md: [8, 46.1184616, -74.5961852],
          lg: [8, 46.1184616, -74.5961852],
          xl: [9, 46.1184616, -74.5961852],
        },
      },
      {
        label: 'Mauricie',
        options: {
          xs: [9, 46.6513137, -72.8470952],
          sm: [9, 46.5825171, -72.7723438],
          md: [9, 46.5618559, -72.7435254],
          lg: [9, 46.5618559, -72.7435254],
          xl: [10, 46.5618559, -72.7435254],
        },
      },
      {
        label: 'La Malbaie',
        options: {
          xs: [11, 47.6569684, -70.152187],
          sm: [11, 47.6569684, -70.152187],
          md: [11, 47.6569684, -70.152187],
          lg: [10, 47.6569684, -70.152187],
          xl: [12, 47.6569684, -70.152187],
        },
      },
      {
        label: 'Estrie',
        options: {
          xs: [9, 45.4042669, -71.893674],
          sm: [9, 45.2238625, -72.3952237],
          md: [9, 45.3440765, -72.5164034],
          lg: [9, 45.3440765, -72.5164034],
          xl: [10, 45.3440765, -72.5164034],
        },
      },
      {
        label: 'Lanaudière',
        options: {
          xs: [9, 46.1256124, -73.704151],
          sm: [9, 46.0450461, -73.8473572],
          md: [9, 46.1256124, -73.704151],
          lg: [9, 46.1256124, -73.704151],
          xl: [11, 46.1256124, -73.704151],
        },
      },
      {
        label: 'Portneuf',
        options: {
          xs: [9, 46.8700005, -71.8237993],
          sm: [10, 46.8118611, -71.8013648],
          md: [9, 46.6984528, -71.8910008],
          lg: [9, 46.6984528, -71.8910008],
          xl: [10, 46.6984528, -71.8910008],
        },
      },
      {
        label: 'Ville de Québec',
        options: {
          xs: [9, 46.8130816, -71.2074596],
          sm: [9, 46.8051985, -71.4163655],
          md: [9, 46.8130816, -71.2074596],
          lg: [9, 46.8130816, -71.2074596],
          xl: [10, 46.8130816, -71.2074596],
        },
      },
      {
        label: 'Chaudière-Appalaches',
        options: {
          xs: [9, 46.3241312, -70.3977787],
          sm: [8, 45.5801342, -70.8881],
          md: [9, 46.1229184, -70.670352],
          lg: [9, 46.1229184, -70.670352],
          xl: [10, 46.4935073, -70.2136365],
        },
      },
      {
        label: 'Centre-du-Québec',
        options: {
          xs: [9, 46.3631986, -72.5804536],
          sm: [9, 46.5618559, -72.7435254],
          md: [9, 46.213625, -72.432438],
          lg: [8, 46.0824975, -72.21627529999999],
          xl: [9, 46.0824975, -72.2162753],
        },
      },
      {
        label: 'Saguenay & Lac Saint-Jean',
        options: {
          xs: [8, 48.620865, -71.111136],
          sm: [8, 48.6401453, -71.5429092],
          md: [9, 48.5549971, -71.32456],
          lg: [9, 49.033333, -71.616667],
          xl: [9, 49.033333, -71.616667],
        },
      },
    ],
  },
]

export const getSearchColumnLocation = (address: string, t: any) => {
  let preDefinedValue: any = null
  const data = getSearchColumns(t)
  for (const column of data) {
    for (const location of column.locations) {
      if (location.label === address) {
        preDefinedValue = location
        break
      }
    }

    if (preDefinedValue) {
      break
    }
  }

  return preDefinedValue
}

/*
export const PopularLocations = [
  {
    label: 'Charlevoix',
    description: 'Charlevoix, QC, Canada',
  },
  {
    label: 'Laurentides',
    description: 'Laurentides, QC, Canada',
  },
  {
    label: 'Estrie',
    description: 'Sherbrooke, QC, Canada',
  },
  {
    label: 'Lanaudière',
    description: 'Lanaudière, QC, Canada',
  },
  {
    label: 'Mauricie',
    description: 'Mauricie, QC, Canada',
  },
  {
    label: 'Capitale-Nationale',
    description: 'Ville de Québec, QC, Canada',
  },
  {
    label: 'Mont-Tremblant',
    description: 'Mont-Tremblant, QC, Canada',
  },
  {
    label: 'Chaudière-Appalaches',
    description: 'Chaudière-Appalaches, QC, Canada',
  },
  {
    label: 'Centre-du-Québec',
    description: 'Centre-du-Québec, QC, Canada',
  },
  {
    label: 'Saguenay',
    description: 'Saguenay, QC, Canada',
  },
  {
    label: 'Lac-St-Jean',
    description: 'Lac St Jean, Quebec, Canada',
  },
]
*/

export const GESTION_COMPLETE_TAG_ID = 81

export const GESTION_PARTIELLE_TAG_ID = 82
