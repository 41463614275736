import {memo, useMemo} from 'react'
import clsx from 'clsx'
import classes from './styles.module.scss'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {
  DATE_STATUS_DISABLED,
  DATE_STATUS_ENABLED_AM,
  DATE_STATUS_ENABLED_PM,
  DATE_STATUS_PAST,
  DateStatusNumber,
  VariantType,
} from '../data'

function DateRangeCalendarDay(props: {
  date?: string
  status?: DateStatusNumber
  price?: string | null
  showPrice?: boolean
  variant?: VariantType | null | undefined
  onClick?: (date: string) => void
  halfDayPicker?: boolean
  selectionDisabled?: boolean
}) {
  const {
    showPrice,
    date,
    halfDayPicker,
    status,
    price,
    variant,
    onClick,
    selectionDisabled,
  } = props

  const statusDisabled = status === DATE_STATUS_DISABLED
  const past = status === DATE_STATUS_PAST

  const day = useMemo(() => {
    if (!date) return null
    //since YYYY-MM-DD make the day the last item
    let day = date.split('-').pop()

    //go from 01 to 1
    if (day && day.startsWith('0')) {
      day = day.replace('0', '')
    }
    return day
  }, [date])

  const dayClassName = useMemo(() => {
    if (!day) {
      return clsx(classes.day)
    }

    if (statusDisabled) {
      return clsx(classes.day, classes.disabledDay)
    }

    if (past) {
      return clsx(classes.day, classes.pastDay)
    }

    if (variant === 'start' || variant === 'end') {
      if (halfDayPicker) {
        if (variant === 'start') {
          return clsx(classes.day)
        }

        return clsx(classes.day, classes.reversedDay, classes.reversedDayText)
      }
      return clsx(classes.day, classes.reversedDay, classes.reversedDayText)
    }

    if (variant === 'middle') {
      return clsx(classes.day, classes.middleDay)
    }

    if (halfDayPicker) {
      return clsx(classes.day, classes.activeDay, classes.halfDayPickerDay)
    }

    return clsx(classes.day, classes.activeDay)
  }, [halfDayPicker, variant, day, past, statusDisabled])

  const textClassName = useMemo(() => {
    let arr = [classes.textBase]
    if (statusDisabled) {
      arr.push(classes.textDisabled)
    }
    if (halfDayPicker) {
      arr.push(classes.textLeft)
    }
    return clsx(arr)
  }, [statusDisabled, halfDayPicker])

  const onClickDate = () => {
    if (statusDisabled || past || selectionDisabled) {
      return
    }

    if (onClick && date) {
      onClick(date)
    }
  }

  return (
    <div className={dayClassName}>
      <AmTriangle
        status={status}
        variant={variant}
        halfDayPicker={halfDayPicker}
        disabled={statusDisabled || selectionDisabled}
      />
      <PmTriangle
        status={status}
        variant={variant}
        halfDayPicker={halfDayPicker}
        disabled={statusDisabled || selectionDisabled}
      />
      <div className={classes.container} onClick={onClickDate}>
        <span className={textClassName}>{day}</span>
        {showPrice && (
          <PriceInfo
            hidden={statusDisabled || past || status === DATE_STATUS_ENABLED_AM}
            price={price}
          />
        )}
      </div>
    </div>
  )
}

function PriceInfo(props: {price?: string | null; hidden: boolean}) {
  const {hidden, price} = props

  if (hidden || !price) {
    return null
  }

  return (
    <div className="pt-1">
      <AppTypography className="select-none" neutralColor={800} variant="small">
        {price}
      </AppTypography>
    </div>
  )
}

function AmTriangle(props: {
  status?: DateStatusNumber
  variant?: VariantType | null
  halfDayPicker?: boolean
  disabled?: boolean
}) {
  const {status, variant, halfDayPicker, disabled} = props

  const className = useMemo(() => {
    if (disabled) {
      return clsx(classes.triangleBase, classes.triangleDisabledAm)
    }

    if (status === DATE_STATUS_ENABLED_PM) {
      return clsx(classes.triangleBase, classes.triangleDisabledAm)
    }
    if (variant === 'start' && !halfDayPicker) {
      return clsx(classes.triangleBase, classes.triangleActiveAm)
    }

    if (variant === 'start' && halfDayPicker) {
      return clsx(classes.triangleBase, classes.triangleHalfDayPickerAm)
    }

    if (variant === 'end') {
      return clsx(classes.triangleBase, classes.triangleActiveAm)
    }
    if (variant === 'middle') {
      return clsx(classes.triangleBase, classes.triangleMiddleAm)
    }

    return clsx(classes.triangleBase)
  }, [disabled, status, variant, halfDayPicker])

  return <div className={className} />
}

function PmTriangle(props: {
  status?: DateStatusNumber
  variant?: VariantType | null
  halfDayPicker?: boolean
  disabled?: boolean
}) {
  const {status, variant, halfDayPicker, disabled} = props

  const className = useMemo(() => {
    if (disabled) {
      return clsx(classes.triangleBase, classes.triangleDisabledPm)
    }
    if (status === DATE_STATUS_ENABLED_AM) {
      return clsx(classes.triangleBase, classes.triangleDisabledPm)
    }

    if (variant === 'end' && !halfDayPicker) {
      return clsx(classes.triangleBase, classes.triangleActivePm)
    }

    if (variant === 'end' && halfDayPicker) {
      return clsx(classes.triangleBase, classes.triangleHalfDayPickerPm)
    }

    if (variant === 'start') {
      return clsx(classes.triangleBase, classes.triangleActivePm)
    }

    if (variant === 'middle') {
      return clsx(classes.triangleBase, classes.triangleMiddlePm)
    }

    return clsx(classes.triangleBase)
  }, [disabled, status, variant, halfDayPicker])

  return <div className={className} />
}

export default memo(DateRangeCalendarDay)
