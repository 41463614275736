import {useTranslation} from 'next-i18next'
import AppTypography from 'src/components/elements/typography/AppTypography'

const weeks = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

export default function DateRangeCalendarHeader() {
  const {t} = useTranslation('common')
  return (
    <div className="w-full flex flex-row justify-start items-center">
      {weeks.map((week: string, index: number) => (
        <Day key={`${index}-${week}`} day={t(week)} />
      ))}
    </div>
  )
}

function Day(props: {day: string}) {
  const {day} = props

  return (
    <div className="flex flex-row justify-center items-center w-[42px] h-[42px]">
      <AppTypography variant="subHeading" neutralColor={500}>
        {day}
      </AppTypography>
    </div>
  )
}
