import {dayjs, Dayjs} from 'src/utils/date'
import {
  BasicPricingFormValues,
  CalendarValues,
  CustomPricingValues,
} from 'src/types/form'
import {useCallback, useState} from 'react'

export function getCalendar(
  date: Dayjs,
  calendars?: CalendarValues[] | null,
): CalendarValues | null {
  if (!calendars) {
    return null
  }

  const filteredCalendars = calendars.filter(
    (calendar: CalendarValues) =>
      date.isBetween(calendar.start, calendar.end) ||
      date.isSame(calendar.start, 'hour') ||
      date.isSame(calendar.end, 'hour'),
  )

  let result: CalendarValues | null = null
  let diffToCompare = 1000
  for (const calendar of filteredCalendars) {
    if (!calendar.is_automatic_period) {
      result = calendar
      break
    }

    const diff = Math.abs(date.diff(dayjs(calendar.start)))
    if (!result) {
      diffToCompare = diff
      result = calendar
      continue
    }

    if (
      result.automatic_period_type === 'season' &&
      calendar.automatic_period_type === 'events'
    ) {
      diffToCompare = diff
      result = calendar
      continue
    }

    if (diffToCompare > diff) {
      diffToCompare = diff
      result = calendar
    }
  }

  return result
}

export function getCalendarsForDate(
  date: Dayjs,
  calendars?: CalendarValues[] | null,
): CalendarValues[] {
  if (!calendars) {
    return []
  }

  const filteredCalendars = calendars.filter((calendar: CalendarValues) => {
    return (
      date.isBetween(calendar.start, calendar.end) ||
      date.isSame(calendar.start, 'hour') ||
      date.isSame(calendar.end, 'hour')
    )
  })

  let automaticPeriods = filteredCalendars.filter((p) => p.is_automatic_period)
  let notAutomaticPeriods = filteredCalendars.filter(
    (p) => !p.is_automatic_period,
  )

  const sortBy = [null, '', 'season', 'events'] //events should be last and have priority
  automaticPeriods = automaticPeriods.sort((a, b) => {
    return sortBy.indexOf(a.automatic_period_type) >
      sortBy.indexOf(b.automatic_period_type)
      ? 1
      : -1
  })

  //not automatic is always more important so put in last position since last position will have priority
  return [...automaticPeriods, ...notAutomaticPeriods]
}

export function getPrice(
  isWeekEnd: boolean,
  priceInfo: BasicPricingFormValues | CustomPricingValues | null,
) {
  if (isWeekEnd && priceInfo?.weekend_rate) {
    return priceInfo?.weekend_rate
  }
  if (isWeekEnd && !priceInfo?.weekend_rate) {
    return priceInfo?.base_rate
  }

  return priceInfo?.base_rate
}
const currentMonth = dayjs().format('YYYY-MM')
const currentNextMonth = dayjs().add(1, 'month').format('YYYY-MM')

export function useVisibleMonths() {
  const [visibleMonths, setVisibleMonths] = useState<string[]>([
    currentMonth,
    currentNextMonth,
  ])

  const nextMonth = useCallback(() => {
    let currentMonthDayJs = dayjs(visibleMonths[0], 'YYYY-MM')
    setVisibleMonths([
      currentMonthDayJs.add(1, 'month').format('YYYY-MM'),
      currentMonthDayJs.add(2, 'month').format('YYYY-MM'),
    ])
  }, [visibleMonths])

  const previousMonth = useCallback(() => {
    let currentMonthDayJs = dayjs(visibleMonths[0], 'YYYY-MM')
    setVisibleMonths([
      currentMonthDayJs.subtract(1, 'month').format('YYYY-MM'),
      currentMonthDayJs.format('YYYY-MM'),
    ])
  }, [visibleMonths])

  return {
    visibleMonths,
    nextMonth,
    previousMonth,
    setVisibleMonths,
  }
}
